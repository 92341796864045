import React from 'react'
import { Helmet } from 'react-helmet'

const IntroductionToCouchbase = () => {
  return (
    <div className="page--what-is-couchbase wrapper mb-8">
      <Helmet title={`Introduction to Couchbase | Couchbase Developer Portal`} />
      <h1>Introduction to Couchbase</h1>
      <p>Couchbase is a distributed document database (JSON), with all the desired capabilities of a relational DBMS; SQL, distributed ACID transactions; and much more. It is a hosted service and a product for hybrid (on-prem and cloud) deployments of mission-critical applications.</p>
      <p>Couchbase has strived to reimagine the database, to serve the modern needs of application developers, instead of debating about SQL, NoSQL, or NewSQL. It is a robust database, built for microservices and serverless consumption-based computing on the cloud on one end, and edge computing for occasionally and locally connected edge Mobile/IoT devices on the other.</p>
      <p>Couchbase’s distributed streaming architecture is designed for no single point of failure. This enables elastic scaling, resource fencing as well as instantaneous data replication for high- availability, global geo-distribution and disaster-recovery. The result is a database that is resilient, cost-efficient, and built for metered usage. This cloud-native architecture combined with Kubernetes (K8s) delivers a self-managed autonomous database.</p>
      <p>Developers write the JSON once into this database and apply multiple data processing capabilities on it. In addition to the full SQL programmability, Couchbase offers Caching, Key- Value store, Search (information retrieval), Analytics (Ad-hoc querying) and Event driven (reactive) programming on this single copy of data.</p>
      <p>Couchbase is designed to interleave transactions with these high-performance operations at large scale. Developers are offered the freedom to pay the price of a transaction only when needed. Consequently, Couchbase can serve as a reliable system of record, while concurrently handling key-value operations of microseconds latency, SQL queries and text searches in milliseconds, and Ad-hoc analytical queries spanning tens of seconds, one not impeding the other.</p>
      <p>These are all capabilities that traditional databases cannot offer out of the box.</p>
      <p>These unique design choices in Couchbase lead to reduced data sprawl, improved security, decreased administration, and lower cost. But most importantly it liberates developers to write applications quickly once and deploy them at any scale.</p>
      <p>Couchbase, in simple terms, is:</p>
      <div>
        <ul>
          <li>A distributed database. No more scaling or availability issues.</li>
          <li>A cache and a database. No more cache invalidation or coherency issues.</li>
          <li>A database and a search engine. No more crawlers.</li>
          <li>An operational and an analytical database. No more ETL.</li>
          <li>A desktop, mobile and clusterable database. No compromises between server and Mobile.</li>
        </ul>
      </div>
      <p>Couchbase is the modern database for Cloud and Edge. No more data sprawl.</p>

      <a className="cb-red-button mb-3" href="https://docs.couchbase.com/server/current/getting-started/start-here.html">Get Started</a>

      <p>Couchbase is available on AWS and Azure as a hosted Service (GCP to come). EKS, AKS and GKE, as well as Linux, Windows, iOS and Android Operating Systems as a product.</p>
      <p>Programmable in your favored languages/frameworks: Java, NodeJS, Python, .NET, Go, Scala, C/C++, Ruby, PHP; iOS - Objective-C, Swift; Android – Java/Kotlin; C#, and JavaScript.</p>

      <a className="cb-black-button" href="https://www.couchbase.com/downloads">Download Now</a>
    </div>
  )
}

export default IntroductionToCouchbase
